// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/not_found.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./subscription_finished.scss"></require>
  <section id="subscription_finished" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="subscription_finished3"></h1>
    </div>
    <div class="flex-grow"></div>
    <img class="icone" src="${___HTML_LOADER_IMPORT_1___}">
    <h3 i18n="subscription_finished1"></h3>
    <p i18n="subscription_finished2"></p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToSubscriptions()" i18n="subscription_finished3"></button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;