import { Router } from 'aurelia-router';
import { autoinject, computedFrom, inject } from 'aurelia-framework';

@autoinject
export class SubscriptionFinished {
    constructor(private router: Router) {
    }
    goToSubscriptions() {
        this.router.navigateToRoute('choose_subscription')
    }
}